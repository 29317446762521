import React, {  } from 'react';
import {IonHeader, IonContent, IonButton, IonInput, IonFooter, useIonViewWillEnter} from '@ionic/react';

type modalProps = {
  closeAction : Function;
  text: string;
}


class LayerpopNonClassifi extends React.Component<modalProps> {

  render() {
    return <>
      {/* <div className="backDropBg"></div> */}
      <IonHeader className="">
        <div className="inner pdbottom_0">
          <div className="txt_frame">
            <p className="txt font_size_24 font_weight_m">{this.props.text}</p>
            <p className="txt font_size_16 lineheight_15">분류 제외 대상의 경우 기존 현장응급처치 표준지침에 근거하여 이송됩니다. 상황실에 문의해주세요.</p>
          </div>
        </div>
      </IonHeader>
      <IonContent>
        <div className="inner">
          <div className="blank_box_50"></div>
          
          <form action="">
            {/* 환자 성명 { */}
            <div className="btnGroupsWrap pdAll_0">
              <ul className="inputGroups flex flex_direc_row flew_wrap lay_1">
                
                <li className="input_box lay_1">
                  <div className="input_frame pdAll_0">
                    <IonInput value="" id="name" className="basic txt font_size_20 font_weight_r" placeholder="환자의 성명을 입력해주세요."></IonInput>
                  </div>
                </li>

              </ul>
            </div>
            {/* } 환자 성명 끝 */}
          </form>

          <div className="blank_box_30"></div>

        </div>
      </IonContent>

      <IonFooter>
        <div className="inner">
          <div className="btnGroupsWrap submitStyle flex flex_direc_row flex_align_center flex_space_between">
            <div className="btn_frame flex flex_glow_1" onClick={() => this.props.closeAction("close")}>
              <IonButton className="btn rounded_box_2 btn_submit font_size_18 font_weight_m" color="secondary">닫기</IonButton>
            </div>

            <div className="btn_frame flex flex_glow_1" onClick={() => this.props.closeAction((document.getElementById('name') as HTMLInputElement).value)}>
              <IonButton type="submit" className="btn rounded_box_2 btn_submit font_size_18 font_weight_m">완료</IonButton>
            </div>
          </div>
        </div>
      </IonFooter>
    </>
  };

}

// export default LayerpopStartClassifi;

export default ({closeAction, text}: { closeAction: Function, text:string}) => (
  <LayerpopNonClassifi closeAction={closeAction} text={text} >
  </LayerpopNonClassifi>
)