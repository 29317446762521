//import './Header.css';

import {IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonMenuButton, IonBackButton, useIonViewWillEnter} from '@ionic/react';
import axios from 'axios';
import { useState } from 'react';
import global from '../assets/json/global.json';

interface HeaderProps {
  hdType: string;
  title: string;
}

const Header: React.FC<HeaderProps> = ({ hdType, title }) => {

  const [noticeCount, setNoticeCount] = useState(0);

  function getNoticeCount() {

    axios.post<Api>(global.baseUrl + '/getNoticeCount').then(response => {

      console.log(response);

      if(response.data.status == '00') {
        let data: any = response.data.data;
        setNoticeCount(data.cnt);
        // alert(data.cnt);
      } else {
        // alert('데이터를 가져오는데 실패했습니다.');
        return;
      }
    }, 
    error => {
      console.log(error);
    });

  }  

  useIonViewWillEnter(() => {
    getNoticeCount();
  })

  function headerCont() {

    // 메인페이지 헤더 (로고, 알림/메뉴버튼)
    if( hdType == "main" ) {
      return(
        <>
        <IonTitle slot="start" class="flex_align_center">
          <h1 className="logo"><img src="assets/images/logo/img-logo.png" alt="" /></h1>
        </IonTitle>

        <IonButtons slot="end">
          <IonButton routerLink="/notice">
            <div className="ico_frame hd_btn position_rltv">
            <img src="assets/images/btn/btn-noti.png" alt="" /> 
            <span className="inline yellowLabel position_absol">{noticeCount}</span>
            </div>
          </IonButton>
          <IonMenuButton ion-icon={false} className="" />
        </IonButtons>
        </>
      );
    }

    // 서브페이지 헤더 (뒤로가기 버튼, 페이지타이틀, 홈/알림/메뉴버튼)
    if( hdType == "sub" ) {
      return(
        <>
        <IonButtons slot="start">
          <IonButton routerDirection="back">
            <IonBackButton defaultHref="home" text="" color="black"> 
            <div className="ico_frame hd_btn">
              <img src="assets/images/btn/btn-back.png" alt="" /> 
            </div>
            </IonBackButton>
          </IonButton>
        </IonButtons>

        <IonTitle class="flex_align_center">
          <h1 className="txt font_size_20 font_weight_m">
            {title}
          </h1>
        </IonTitle>

        <IonButtons slot="end">
          <IonButton routerLink="/home" routerDirection="root">
            <div className="ico_frame hd_btn">
            <img src="assets/images/btn/btn-home.png" alt="" /> 
            </div>
          </IonButton>
          <IonButton routerLink="/notice">
            <div className="ico_frame hd_btn position_rltv">
            <img src="assets/images/btn/btn-noti.png" alt="" /> 
            <span className="inline yellowLabel position_absol">{noticeCount}</span>
            </div>
          </IonButton>
          <IonMenuButton ion-icon={false} className="" />
        </IonButtons>
        </>
      );
    }

  }

  return (
    <>
      <IonHeader id="header" className={hdType}>
        <IonToolbar class="">

          {headerCont()}

        </IonToolbar>
      </IonHeader>
    </>
  );
  
};

export default Header;
