import React from 'react';
import {IonHeader, IonContent, IonButton} from '@ionic/react';


type modalProps = {
  closeAction : Function;
}


class LayerpopStartClassifi extends React.Component<modalProps> {

  render() {
    return <>
      <IonHeader className="">
        <div className="inner">
          <div className="txt_frame">
            <p className="txt font_size_24 font_weight_m">중증도 분류 시작하기</p>
            <p className="txt font_size_16 color_77">환자의 상태를 선택해주세요.</p>
          </div>
        </div>
      </IonHeader>
      <IonContent className="">
        <div className="inner">
          <div className="btn_frame">
            <IonButton className="btn rounded_box blue" routerLink="/trauma" onClick={() => this.props.closeAction()}>
              <p className="txt font_size_24 font_weight_m color_white width_100 flex flex_direc_row flex_align_center flex_space_between">
                외상 환자 <i className="ico ico_btn_arr white"></i>
              </p>
            </IonButton>
          </div>
          <div className="btn_frame">
            <IonButton className="btn rounded_box blue" routerLink="/traumanon/new"  onClick={() => this.props.closeAction()}>
              <p className="txt font_size_24 font_weight_m color_white width_100 flex flex_direc_row flex_align_center flex_space_between">
                비외상 환자 <i className="ico ico_btn_arr white"></i>
              </p>
            </IonButton>
          </div>
        </div>
      </IonContent>
    </>
  };

}

// export default LayerpopStartClassifi;

export default ({closeAction}: { closeAction: Function }) => (
  <LayerpopStartClassifi closeAction={closeAction}>
  </LayerpopStartClassifi>
)