import { IonContent, IonButton, IonPage, IonLabel, IonInput, useIonViewWillEnter, useIonViewDidEnter } from '@ionic/react';
import { RouteComponentProps, useParams } from 'react-router';
import { Storage } from '@capacitor/storage';
import { Geolocation } from '@capacitor/geolocation';
import axios from 'axios';

import global from '../assets/json/global.json';
import './Page.css';
import { version } from 'process';

const Login: React.FC<RouteComponentProps> = (props) => {

  function versionCheck() {
    axios.post<Api>(global.baseUrl + '/getVersion').then(response => {

      console.log(response);

      if(response.data.status == '00') {
        console.log(response.data.data);
        let version = parseInt(response.data.data);
        if(version > global.version) {
          if(window.confirm('업데이트가 있습니다. 다운로드 화면으로 이동합니다.')) {
            window.open(global.baseUrl + '/download');
          }
        }
      } else {
        alert('자동로그인에 실패했습니다. ID와 비밀번호를 입력 후 로그인해주세요!');
        return;
      }

    }, 
    error => {

    });    
  }

  useIonViewDidEnter(() => {
    versionCheck();
  });

  const getLoginInfo = async () => {
    const { value } = await Storage.get({key: 'loginInfo'});
    return JSON.parse(value ? value : "");
  };

  useIonViewWillEnter(() => {
    getLoginInfo().then(response => {
      if(response.loginToken != "") {
        tokenLogin(response.loginToken);
      }
    });
  });

  function tokenLogin(loginToken: string) {

    console.log('::::: Login > tokenLogin :::::');

    let params = {loginToken: loginToken};
    let strParams = JSON.stringify(params);

    axios.post<Api>(global.baseUrl + '/login', strParams).then(response => {

      console.log(response);

      if(response.data.status == '00') {
        Storage.set({
          key: 'loginInfo', 
          value: JSON.stringify(response.data.data)
        });
        props.history.replace('/home');
      } else {
        alert('자동로그인에 실패했습니다. ID와 비밀번호를 입력 후 로그인해주세요!');
        return;
      }

    }, 
    error => {

    });

  }

  function submitLogin() {

    console.log('::::: Login > submitLogin :::::');

    let params = {
        'id': (document.getElementById('id') as HTMLInputElement).value, 
        'password': (document.getElementById('password') as HTMLInputElement).value
    };
    let strParams = JSON.stringify(params);

    axios.post<Api>(global.baseUrl + '/login', strParams).then(response => {

      console.log(response);

      if(response.data.status == '00') {
        Storage.set({
          key: 'loginInfo', 
          value: JSON.stringify(response.data.data)
        });
        props.history.replace('/home');
      } else {
        alert('로그인에 실패했습니다. ID와 비밀번호를 확인해주세요!');
        return;
      }

    }, 
    error => {
      console.log(error);
    });


  }

  return (
    <IonPage id="page_login" >


      <IonContent fullscreen >

        <div className="contWrap flex flex_direc_col flex_justi_center flex_align_center width_100 minhegiht_100 bg_wgrey">
          <div className="inner flex flex_direc_col flex_glow_2 flex_align_center flex_justi_center">

            {/* 로고 */}
            <h1 className="logo symbol"><img src="assets/images/logo/img-logo-symbol.png" alt="" /></h1>

            {/* form: 로그인 { */}
            <form action="" name="" id="" onSubmit={() => submitLogin()}>
            {/* 입력란 { */}
            <ul className="inputGroups flex flex_direc_row flew_wrap lay_1">
              <li className="input_box lay_1">
                <div className="input_frame">
                  <IonInput value="" name="" id="id" className="basic shadow txt font_size_20 font_weight_r" placeholder="아이디"></IonInput>
                </div>
              </li>
              <li className="input_box lay_1">
                <div className="input_frame">
                  <IonInput type="password" value="" name="" id="password" className="basic shadow txt font_size_20 font_weight_r" placeholder="패스워드"></IonInput>
                </div>
              </li>
            </ul>
            {/* } 입력란 끝 */}

            {/* 자동로그인 체크, 계정찾기 버튼 { */}
            <div className="btn_box width_100 flex flex_direc_row flex_align_center flex_space_between">
              <div className="left">
                <label htmlFor="ck_autologin" className="ck_box flex flex_direc_row flex_align_center">
                  {/* 체크박스: 자동로그인 */}
                  <input type="checkbox" name="" id="ck_autologin" className="inputhide" value="" />
                  <i className="ico ico_btn_check"></i>
                  <div className="inline blank_box_10"></div>
                  <div className="txt_frame">
                    <p className="txt font_size_16 font_weight_r">자동 로그인</p>
                  </div>
                </label>
              </div>
              <div className="right">
                {/* 버튼: 계정찾기 */}
                {/* <div className="txt_frame btn">
                  <p className="txt font_size_16">계정찾기</p>
                </div> */}
              </div>
            </div>
            {/* } 자동로그인 체크, 계정찾기 버튼 */}

            {/* 버튼 Submit { */}
            <div className="btnGroupsWrap submitStyle">
              <div className="btn_frame">
                {/* type="submit", routerLink 삭제 */}
                {/* <IonButton type="button" routerLink="/home" className="btn rounded_box_3 boxshadow btn_submit font_size_18 font_weight_m">로그인</IonButton> */}
                <IonButton type="button" onClick={() => submitLogin()} className="btn rounded_box_3 boxshadow btn_submit font_size_18 font_weight_m">로그인</IonButton>
              </div>
            </div>
            {/* } 버튼 Submit 끝 */}
            </form>
            {/* } form: 로그인 끝 */}

          </div>

          <div className="co_logos">
            <div className="inner flex flex_direc_row width_100 flex_justi_center flex_align_center">
              <div className="img_frame">
                <img src="assets/images/logo/logo-mohw.png" alt="" />
              </div>              
              <div className="img_frame">
                <img src="assets/images/logo/logo-daejeon.png" alt="" />
              </div>
            </div>
          </div>
        </div>

      </IonContent>

    </IonPage>
  );
};

export default Login;