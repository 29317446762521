//import './Header.css';

import {IonModal, IonButton} from '@ionic/react';

// 레이어팝업: 중증도 분류 시작하기
import LayerpopStartClassifi from '../components/LayerpopStartClassifi';

import { useState } from 'react';


const SubHeader: React.FC = () => {

  const [showModal, setShowModal] = useState(false);
  async function closeModal() {
    await setShowModal(false);
  }

  return(
    <>
    <div id="subHd">
      <div className="btn_frame">
        <IonButton className="btn rounded_box blue"
        onClick={() => setShowModal(true)}>
          <p className="txt font_size_34 font_weight_m color_white flex flex_direc_row flex_align_center flex_space_between">
            중증도 분류 시작하기 <i className="ico ico_arr_start"></i>
          </p>
        </IonButton>
      </div>
    </div>

    
    {/* 레이어팝업: 중증도 분류 시작하기 { */}
    <IonModal id="layerpopStartClassifi" mode="ios" backdropDismiss={false} isOpen={showModal} > 
      <LayerpopStartClassifi closeAction={closeModal}/> 
    </IonModal>
    {/* } 레이어팝업: 중증도 분류 시작하기  끝 */}
    </>
  );
  
};

export default SubHeader;
