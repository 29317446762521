import { IonContent, IonButton, IonPage, IonModal, useIonViewDidLeave, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { useParams, RouteComponentProps } from 'react-router';
import { useState } from 'react';
import Header from '../components/Header';
import LayerpopParameter from '../components/LayerpopParameter';
import { Storage } from '@capacitor/storage';


import './Page.css';

import LayerpopParameterS from '../components/LayerpopParameterS';


const TraumaNon: React.FC<RouteComponentProps> = (props) => {

  const { name, extra } = useParams<{ name: string, extra: string; }>();
  const [ score, setScore ] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [ scoreStr, setScoreStr ] = useState(['', '', '', '', '', '', '', '']);
  const [ checked, setChecked ] = useState([false, false, false, false, false, false, false, false]);
  const [ lastScore, setLastScore ] = useState(0);
  const [ lastTime, setLastTime ] = useState(0);

  let news2List = [
    '수축기 혈압', 
    '분당 맥박수',     
    '분당 호흡수', 
    '체온', 
    '혈중 산소 포화도', 
    '혈중 산소 포화도(만성 폐쇄성 폐질환)', 
    '산소 투여 여부', 
    '의식 수준'    
  ];

  useIonViewWillEnter(() => {
    window.scrollTo(0, 0);
    getSelectInfo().then(response => {
      if(response && response.news2 != "") {
        let news2 = JSON.parse(response.news2);
        setScore(news2.score);
        setChecked(news2.checked);
        setScoreStr(news2.scoreStr);
      } else {
        reset();
      }
    });
  });

  function reset() {
    setScore([0, 0, 0, 0, 0, 0, 0, 0]);
    setChecked([false, false, false, false, false, false, false, false])
    setScoreStr(['', '', '', '', '', '', '', ''])
  }

  interface ntsItem {
    nm: string;
    score: number;
  }
  const [showModal, setShowModal] = useState({isOpen: false, type: ""});
  const [showModal2, setShowModal2] = useState({isOpen: false, type: ""});

  function openModal(param: any) {
    const currentTime = new Date().getTime();
    let timeout = 0;

    if(currentTime - lastTime < 300)
      timeout = 300 - (currentTime - lastTime);

    console.log("::::: openModal timeout " + lastTime + " / " + currentTime + " / " + timeout + " =====");

    setTimeout(() => {
      setShowModal(param);
    }, timeout);
  }

  function closeModal(idx: string, item: ntsItem) {

    setLastTime(new Date().getTime());
    setShowModal({isOpen: false, type: ""});

    console.log('::::: TraumaNon > closeModalProcess :::::');
    console.log(idx);
    console.log(item);

    let idxn = parseInt(idx);

    if(idx != "" && item.score > -1) {

      if(idxn == 4) {
        setChecked([...checked.slice(0, idxn), true, false, ...checked.slice(idxn+2)])
        setScore([...score.slice(0, idxn), item.score, 0, ...score.slice(idxn+2)])
        setScoreStr([...scoreStr.slice(0, idxn), item.nm, "", ...scoreStr.slice(idxn+2)])
      } else if(idxn == 5) {
        setChecked([...checked.slice(0, idxn - 1), false, true, ...checked.slice(idxn+1)])
        setScore([...score.slice(0, idxn - 1), 0, item.score, ...score.slice(idxn+1)])
        setScoreStr([...scoreStr.slice(0, idxn - 1), "", item.nm, ...scoreStr.slice(idxn+1)])        
      } else {
        setChecked([...checked.slice(0, idxn), true, ...checked.slice(idxn+1)])
        setScore([...score.slice(0, idxn), item.score, ...score.slice(idxn+1)])
        setScoreStr([...scoreStr.slice(0, idxn), item.nm, ...scoreStr.slice(idxn+1)])
      }



    }

  }

  

  function closeModal2(idx: string, item: ntsItem) {

    console.log(idx);
    console.log(item);

    if(idx == "8") {
      let type = item.score + '';
      setShowModal({isOpen: true, type: type});
      setShowModal2({isOpen: false, type: ""});
    }

  }

  /*
  function checkAllChecked() {
    let isAllChecked = true;
    for(let i = 0; i < checked.length; i++) {
      if(checked[i] != "checked") {
        isAllChecked = false;
      }
    }

    if(!isAllChecked) {
      alert("선택되지 않은 항목이 있습니다.");
      return false;
    }    

    // routerLink={"/scoring/" + lastScore}
    history.push('/scoring/' + lastScore)
    
  }
  */

  function calcNews2() {

    /*
    let isAllChecked = true;
    for(let i = 0; i < checked.length; i++) {
      if(checked[i] != "checked") {
        isAllChecked = false;
      }
    }

    if(!isAllChecked) {
      alert("선택되지 않은 항목이 있습니다.");
      return;
    }
    */

    let total = 0;
    for(let i = 0; i < score.length; i++) {
      total += score[i]
    }
    
    let lastScore = 0;
    if(total >= 7) lastScore = 3;
    else if(total >= 5 && total <= 6) lastScore = 2;
    else if(total < 5) lastScore = 1;

    setLastScore(lastScore);

  }

  const getSelectInfo = async () => {
    const { value } = await Storage.get({key: 'selectInfo'});
    return JSON.parse(value ? value : "");
  };

  function save() {

    console.log('::::: TraumaNon > save :::::');

    let total = 0;
    for(let i = 0; i < score.length; i++) {
      total += score[i]
    }
    console.log('NEWS2 점수 합계 : ' + total);
    
    let lastScore = 0;
    if(total >= 7) lastScore = 3;
    else if(total >= 5 && total <= 6) lastScore = 2;
    else if(total < 5) lastScore = 1;
    setLastScore(lastScore);
    console.log('NEWS2 최종 점수 환산 : ' + lastScore);
    

    //기존 저장정보 불러와서 최종 점수 계산
    getSelectInfo().then(response => {

      response.news2 = JSON.stringify(
        {
          'score': score, 
          'scoreStr': scoreStr, 
          'checked': checked
        }
      );      
      response.news2Total = total;
      response.news2Score = lastScore;

      Storage.set({key: 'selectInfo', value: JSON.stringify(response)});

      props.history.push('/scoring');

    });    

  }  

  return (
    <IonPage id={ 'page_' + name } >

      {/* Header { */}
      <Header hdType="sub" title="중증도 분류" />
      {/* } Header */}


      <IonContent fullscreen >

        {/* 컨텐츠 타이틀 { */}
        <div className="ContTit">
          <h2 className="txt font_size_28 font_weight_m color_main">NEWS 2 <span className="inline blank_box_10"></span><p className="inline txt font_size_18 color_77">National Early Warning Score 2</p></h2>
          <div className="blank_box_20"></div>
          <div className="line hori short sky"></div>
        </div>
        {/* } 컨텐츠 타이틀 끝 */}


        {/* 중증도분류-외상환자 1 { */}
        <div className="btnGroupsWrap listStyle">
          <div className="txt_frame groupTit">
            <p className="txt font_size_20 font_weight_m">Physiological parameter</p>
          </div>

          <ul className="flex flex_direc_row flew_wrap lay_1">
            
            <li className="btn_frame lay_1" >
              <IonButton className={"btn rounded_box boxshadow " + (checked[0] ? "selected" : "white")}  color="white"  onClick={() => openModal({isOpen: true, type: "0"})}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{news2List[0]}</p>
                <p className="txt font_size_16">{scoreStr[0]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className="btn_frame lay_1" >
              <IonButton className={"btn rounded_box boxshadow " + (checked[1] ? "selected" : "white")}  color="white"  onClick={() => openModal({isOpen: true, type: "1"})}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{news2List[1]}</p>
                <p className="txt font_size_16">{scoreStr[1]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className="btn_frame lay_1" >
              <IonButton className={"btn rounded_box boxshadow " + (checked[2] ? "selected" : "white")}  color="white"  onClick={() => openModal({isOpen: true, type: "2"})}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{news2List[2]}</p>
                <p className="txt font_size_16">{scoreStr[2]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className="btn_frame lay_1" >
              <IonButton className={"btn rounded_box boxshadow " + (checked[3] ? "selected" : "white")}  color="white"  onClick={() => openModal({isOpen: true, type: "3"})}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{news2List[3]}</p>
                <p className="txt font_size_16">{scoreStr[3]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>                                    

            <li className={"btn_frame lay_1 " + (checked[4] || checked[5] ? "displaynone" : "")} >
              <IonButton className="btn rounded_box boxshadow white"  color="white" onClick={() => setShowModal2({isOpen: true, type: "8"})}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">혈중 산소 포화도</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className={"btn_frame lay_1 " + (checked[4] ? "" : "displaynone")}>
              {/* <IonButton className={"btn rounded_box boxshadow " + (checked[1] ? "selected" : "white")}  color="white" onClick={() => setShowModal({isOpen: true, type: "1"})}> */}
              <IonButton className={"btn rounded_box boxshadow " + (checked[4] ? "selected" : "white")}  color="white" onClick={() => setShowModal2({isOpen: true, type: "8"})}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{news2List[4]}</p>
                <p className="txt font_size_16">{scoreStr[4]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className={"btn_frame lay_1 " + (checked[5] ? "" : "displaynone")}>
              {/* <IonButton className={"btn rounded_box boxshadow " + (checked[2] ? "selected" : "white")}  color="white" onClick={() => setShowModal({isOpen: true, type: "2"})}> */}
              <IonButton className={"btn rounded_box boxshadow " + (checked[5] ? "selected" : "white")}  color="white" onClick={() => setShowModal2({isOpen: true, type: "8"})}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{news2List[5]}</p>
                <p className="txt font_size_16">{scoreStr[5]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className="btn_frame lay_1 " >
              <IonButton className={"btn rounded_box boxshadow " + (checked[6] ? "selected" : "white")}  color="white" onClick={() => openModal({isOpen: true, type: "6"})}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{news2List[6]}</p>
                <p className="txt font_size_16">{scoreStr[6]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className="btn_frame lay_1 " >
              <IonButton className={"btn rounded_box boxshadow " + (checked[7] ? "selected" : "white")}  color="white" onClick={() => openModal({isOpen: true, type: "7"})}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{news2List[7]}</p>
                <p className="txt font_size_16">{scoreStr[7]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className="width_100 txt_frame btn_frame">
              <p className="txt font_size_16 color_99 align_r">* Royal College of Physicians 2018</p>
            </li>

          </ul>
        </div>
        {/* } 중증도분류-외상환자 1 */}

        {/* 버튼 Submit { */}
        <div className="btnGroupsWrap submitStyle">
          <div className="btn_frame">
          {/* type="submit" , routerLink 삭제하기 */}
          {/* routerLink="/hospitalinfo" */}
            {/* <IonButton type="button" onClick={() => setShowModal(true)} className="btn rounded_box_2 btn_submit font_size_18 font_weight_m">선택 완료</IonButton> */}
            {/* <IonButton type="button" routerLink={"/scoring/" + lastScore} className="btn rounded_box_2 btn_submit font_size_18 font_weight_m">선택 완료</IonButton> */}
            <IonButton type="button" onClick={() => save()} className="btn rounded_box_2 btn_submit font_size_18 font_weight_m">선택 완료</IonButton>
          </div>
        </div>
        {/* } 버튼 Submit 끝 */}        

      </IonContent>


      {/* 레이어팝업: 중증도 분류 시작하기 { */}
      <IonModal id="LayerpopParameter" mode="ios" backdropDismiss={false} isOpen={showModal.isOpen} animated={false} > 
        <LayerpopParameter closeAction={closeModal} text={showModal.type} extra={extra}/> 
      </IonModal>

      <IonModal id="LayerpopParameterS" mode="ios" backdropDismiss={false} isOpen={showModal2.isOpen} animated={false} > 
        <LayerpopParameterS closeAction={closeModal2} text={showModal2.type} extra={extra}/> 
      </IonModal>
      {/* } 레이어팝업: 중증도 분류 시작하기  끝 */}

    </IonPage>
  );
};

export default TraumaNon;
