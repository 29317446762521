import { IonContent, IonButton, IonPage, IonModal, useIonViewWillEnter } from '@ionic/react';
import { RouteComponentProps, useParams } from 'react-router';
import React, { useState } from 'react';
import { Storage } from '@capacitor/storage';
// import ExploreContainer from '../components/ExploreContainer';

// 헤더
import Header from '../components/Header';
import SubHeader from '../components/SubHeader';

// 레이어팝업: 분류 제외 대상
import LayerpopNonClassifi from '../components/LayerpopNonClassifi';


import './Page.css';

const Page: React.FC<RouteComponentProps> = (props) => {

  const { name } = useParams<{ name: string; }>();

  const [showModal, setShowModal] = useState({isOpen: false, subject: ""});
  async function closeModal(modalName: string) {

    let subject = showModal.subject;

    if(modalName == "close") {
      await setShowModal({isOpen: false, subject: ""});
    } else {
      // if(modalName != "") {
        await setShowModal({isOpen: false, subject: ""});
        console.log('name: ' + modalName);
        let tmp: any = selectInfo;
        tmp.name = modalName;
        if(subject == "화상") {
          tmp.isBurn = true;
        }
        tmp.extra = subject;
        Storage.set({key: 'selectInfo', value: JSON.stringify(tmp)});
        props.history.push('/hospitalInfo', null);
      // } else {
      //   alert("이름을 입력해주세요!");
      // }
    }

  }

  let selectInfo = {
    name: "", 
    trauma: -1, 
    traumaStr: "", 
    scoring: "",
    scoringScore: 0, 
    news2: "", 
    news2Total: 0, 
    news2Score: 0, 
    level: 0, 
    isBurn: false
  };

  const selectInfoOriginal = {
    name: "", 
    trauma: -1, 
    traumaStr: "", 
    scoring: "",
    scoringScore: 0, 
    news2: "", 
    news2Total: 0, 
    news2Score: 0, 
    level: 0, 
    isBurn: false
  };  

  useIonViewWillEnter(() => {
    console.log('::::: Page > useIonViewWillEnter > resetSelectInfo');
    console.log(selectInfo);
    Storage.set({key: 'selectInfo', value: JSON.stringify(selectInfoOriginal)});
  });

  // const setLogin = async () => {
  //   await Storage.set({
  //     key: 'loginToken', 
  //     value: 'ABCD1234'
  //   });
  // };
  // setLogin();

  return (
    <IonPage id={ 'page_' + name } >

      {/* Header { */}
      <Header hdType="main" title="" />
      {/* } Header */}

      <IonContent fullscreen >
        {/* <ExploreContainer name={name} /> */}

        {/* main { */}
        <div id="mainPage" className="page">
          {/* sub header */}
          <SubHeader />

          {/*  */}
          <div className="cont_wrap">
            <div className="txt_frame tit">
              <p className="txt font_size_20 font_weight_m">분류 제외 대상</p>
              <div className="blank_box_5"></div>
              <p className="txt font_size_16 font_weight_l color_77">분류 제외 대상의 경우 기존 현장 응급처치 표준지침에 근거하여 이송합니다.</p>
            </div>
            
            <div className="mainBtnsWrap lay_1">
              <ul className="flex flex_direc_row flew_wrap">
                
                {/* 버튼 1: 심정지 */}
                <li className="btn_frame lay_2_1">
                  <IonButton className="btn rounded_box white boxshadow" color="white" onClick={() => setShowModal({isOpen: true, subject: "심정지"})}>
                    <div className="txt font_size_24">
                      <div className="img_frame">
                        <img src="assets/images/btn/btn-except-1.png" alt="" className="icon" />
                      </div>
                      심정지
                    </div>
                    <i className="ico ico_btn_arr"></i>
                  </IonButton>
                </li>

                {/* 버튼 2: 심뇌혈관 질환 */}
                <li className="btn_frame lay_2_1">
                  <IonButton className="btn rounded_box white boxshadow" color="white" onClick={() => setShowModal({isOpen: true, subject: "심뇌혈관 질환"})}>
                    <div className="txt font_size_24">
                      <div className="img_frame">
                        <img src="assets/images/btn/btn-except-2.png" alt="" className="icon" />
                      </div>
                      심뇌혈관 <br /> 질환
                    </div>
                    <i className="ico ico_btn_arr"></i>
                  </IonButton>
                </li>

                {/* 버튼 3: 응급분만 */}
                <li className="btn_frame lay_2_1">
                  <IonButton className="btn rounded_box white boxshadow" color="white" onClick={() => setShowModal({isOpen: true, subject: "응급분만"})}>
                    <div className="txt font_size_24">
                      <div className="img_frame">
                        <img src="assets/images/btn/btn-except-3.png" alt="" className="icon" />
                      </div>
                      응급분만
                    </div>
                    <i className="ico ico_btn_arr"></i>
                  </IonButton>
                </li>

                {/* 버튼 4: 소아 */}
                <li className="btn_frame lay_2_1">
                  <IonButton className="btn rounded_box white boxshadow" color="white" onClick={() => setShowModal({isOpen: true, subject: "소아"})}>
                    <div className="txt font_size_24">
                      <div className="img_frame">
                        <img src="assets/images/btn/btn-except-4.png" alt="" className="icon" />
                      </div>
                      소아
                    </div>
                    <i className="ico ico_btn_arr"></i>
                  </IonButton>
                </li>

                {/* 버튼 5: 행동이상 */}
                <li className="btn_frame lay_2_1">
                  <IonButton className="btn rounded_box white boxshadow" color="white" onClick={() => setShowModal({isOpen: true, subject: "행동이상"})}>
                    <div className="txt font_size_24">
                      <div className="img_frame">
                        <img src="assets/images/btn/btn-except-7.png" alt="" className="icon" />
                      </div>
                      행동이상
                    </div>
                    <i className="ico ico_btn_arr"></i>
                  </IonButton>
                </li>

                {/* 버튼 5: 자살 */}
                <li className="btn_frame lay_2_1">
                  <IonButton className="btn rounded_box white boxshadow" color="white" onClick={() => setShowModal({isOpen: true, subject: "자살"})}>
                    <div className="txt font_size_24">
                      <div className="img_frame">
                        <img src="assets/images/btn/btn-except-5.png" alt="" className="icon" />
                      </div>
                      자살
                    </div>
                    <i className="ico ico_btn_arr"></i>
                  </IonButton>
                </li>                

                {/* 버튼 6: 화상 */}
                <li className="btn_frame lay_2_1">
                  <IonButton className="btn rounded_box white boxshadow" color="white" onClick={() => setShowModal({isOpen: true, subject: "화상"})}>
                    <div className="txt font_size_24">
                      <div className="img_frame">
                        <img src="assets/images/btn/btn-except-6.png" alt="" className="icon" />
                      </div>
                      화상
                    </div>
                    <i className="ico ico_btn_arr"></i>
                  </IonButton>
                </li>

              </ul>
            </div>
          </div>
        </div>
        {/* } main 끝 */}

      </IonContent>


      {/* 레이어팝업: 분류 제외 대상 { */}
      <IonModal id="layerpopNonClassifi" mode="ios" backdropDismiss={false} isOpen={showModal.isOpen} > 
        <LayerpopNonClassifi closeAction={closeModal} text={showModal.subject}/> 
      </IonModal>
      {/* } 레이어팝업: 분류 제외 대상  끝 */}

    </IonPage>
  );
};

export default Page;