import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';
import Trauma from './pages/Trauma';
import TraumaNon from './pages/TraumaNon';
import Scoring from './pages/Scoring';
import HospitalInfo from './pages/HospitalInfo';
import Notice from './pages/Notice';
import Login from './pages/Login';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* cnuEr custom css */
import './theme/template.css';
import HospitalManage from './pages/HospitalManage';
import NoticeWrite from './pages/NoticeWrite';

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main" disabled={true}>
          <Menu />
          <IonRouterOutlet id="main">

            {/* 기본경로 - 메인(홈) */}
            <Route path="/" exact={true}>
              <Redirect to="/login" />
            </Route>

            {/* 로그인 */}
            <Route path="/login" exact={true} component={Login} />

            {/* 홈 */}
            <Route path="/home" exact={true} component={Page} />
            
            {/* 중증도분류 - 외상 */}
            <Route path="/trauma" exact={true} component={Trauma} />

            {/* 중증도분류 - 비외상 */}
            <Route path="/traumanon" exact={true} component={TraumaNon} />
            <Route path="/traumanon/:extra" exact={true} component={TraumaNon} />

            {/* 중증도분류 - 현장응급처치표준지침Scoring */}
            <Route path="/scoring" exact={true} component={Scoring} />
            <Route path="/scoring/:score" exact={true} component={Scoring} />
            <Route path="/scoring/:score/:extra" exact={true} component={Scoring} />

            {/* 병원정보 */}
            <Route path="/hospitalinfo" exact={true} component={HospitalInfo} />
            <Route path="/hospitalinfo/:subject" exact={true} component={HospitalInfo} />
            <Route path="/hospitalinfo/:subject/:extra" exact={true} component={HospitalInfo} />

            {/* 공지사항 */}
            <Route path="/notice" exact={true} component={Notice} />

            {/* 공지사항 등록 */}
            <Route path="/noticeWrite" exact={true} component={NoticeWrite} />

            {/* 공지사항 수정 */}
            <Route path="/noticeWrite/:uid" exact={true} component={NoticeWrite} />

            {/* 병원정보 관리 */}
            <Route path="/hospitalManage" exact={true} component={HospitalManage} />            

          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;