import React from 'react';
import {IonHeader, IonContent, IonButton, IonFooter} from '@ionic/react';


type modalProps = {
  closeAction : Function;
  text: string;
}


class LayerpopAlert extends React.Component<modalProps> {

  render() {
    return <>
      {/* <div className="backDropBg"></div> */}
      <IonHeader className="">
        <div className="blank_box_20"></div>
        <div className="inner pdbottom_0">
          <div className="txt_frame">
            <p className="txt font_size_24 font_weight_m align_c lineheight_16"> 
              {this.props.text}
            </p>
          </div>
        </div>
      </IonHeader>

      <IonContent>
      </IonContent>

      <IonFooter>
        <div className="inner">
          <div className="btnGroupsWrap submitStyle flex flex_direc_row flex_align_center flex_space_between">

            <div className="btn_frame flex flex_glow_1" onClick={() => this.props.closeAction()}>
              <IonButton type="button" className="btn rounded_box_2 btn_submit font_size_18 font_weight_m">닫기</IonButton>
            </div>

          </div>
        </div>
      </IonFooter>
    </>
  };

}

// export default LayerpopAlert;

export default ({closeAction, text}: { closeAction: Function, text:string}) => (
  <LayerpopAlert closeAction={closeAction} text={text}></LayerpopAlert>
)