import { IonContent, IonButton, IonPage, IonModal, IonInput, IonRow, useIonViewWillEnter, useIonViewDidEnter } from '@ionic/react';
import { useParams, useHistory, RouteComponentProps } from 'react-router';
import { Storage } from '@capacitor/storage';
import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';

// 레이어팝업: 환자레벨확인
import LayerpopLevel from '../components/LayerpopLevel';
import LayerpopAlert from '../components/LayerpopAlert';

import './Page.css';

import values from '../assets/json/scoring.json';

const Scoring: React.FC<RouteComponentProps> = (props) => {

  // const { score, extra } = useParams<{ score: string, extra: string }>();

  const [alertMessage, setAlertMessage] = useState("이름을 입력해주세요.");
  const [level, setLevel] = useState("3");
  const [extra, setExtra] = useState("");
  
  const [name, setName] = useState("");
  const updateName = (event: any) => {
    setName(event.target.value)
  }

  const [showModal, setShowModal] = useState(false);
  async function closeModal() {
    await setShowModal(false);
    props.history.push('/hospitalInfo');
  }

  const [showAlert, setShowAlert] = useState(false);
  async function closeAlert() {
    await setShowAlert(false);
  }

  let cloneValues = [...values];
  const [scoreList, setScoreList] = useState(cloneValues);
  const [checkedList, setCheckedList] = useState([{id: "", score: 0, nm: ""}]);

  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToTop= () => {
      contentRef.current && contentRef.current.scrollToTop();
  };

  useIonViewWillEnter(() => {
    console.log(':::: Scoring > useIonViewWillEnter :::::');
    props.location.state = null;

    getSelectInfo().then(response => {
      if(response && response.name != "") {
        setName(response.name);
      } else {
        setName("");
      }

      if(response && response.scoring != "") {
        let scoring = JSON.parse(response.scoring);
        setScoreList(scoring.list);
        setCheckedList(scoring.checkedList);
      } else {
        let tmpScoreList = cloneValues;
        for(let i = 0; i < tmpScoreList.length; i++) {
          for(let j = 0; j < tmpScoreList[i].list.length; j++) {
            tmpScoreList[i].list[j].isChecked = false;
          }
        }
        setScoreList(tmpScoreList);
        scrollToTop();
        window.scrollTo(0, 0);        
      }
    });

  });

  const getSelectInfo = async () => {
    const { value } = await Storage.get({key: 'selectInfo'});
    return JSON.parse(value ? value : "");
  };

  interface ScoringItem {
    isChecked: boolean;
    nm: string;
    score: number;
  }
  function checkChecked(event: any, item: ScoringItem, pindex: number, pidx: number) {

    let tmpScoreList = scoreList[pindex];
    tmpScoreList.list[pidx].isChecked = !tmpScoreList.list[pidx].isChecked;
    setScoreList([...scoreList.slice(0, pindex), tmpScoreList, ...scoreList.slice(pindex+1)]);

    let id = event.target.id
    let idx = -1
    checkedList.map((item, index) => {
      if(item.id == id) idx = index
    })
    if(idx > -1) checkedList.splice(idx, 1);
    else checkedList.push({id: id, score: item.score, nm: item.nm})

    setCheckedList(checkedList)

  }

  function save() {

    console.log('::::: Scoring > save :::::');

    // if(name == "") {
    //   setShowAlert(true);
    //   return;
    // }

    let scoring = JSON.stringify({
      'list': scoreList, 
      'checkedList': checkedList
    });
    let scoringScore = 0;
    checkedList.map((item) => {
      // scoring.push({id: item.id, nm: item.nm, score: item.score});
      if(item.score > scoringScore) scoringScore = item.score
    });
    // let lastScoring = JSON.stringify(scoring);

    //기존 저장정보 불러와서 최종 점수 계산
    getSelectInfo().then(response => {
      let isTrauma = response.trauma > -1 && response.trauma < 5 ? true : false;
      let news2Score = response.news2Score;
      let totalScore = news2Score + scoringScore;
      if(response.trauma == 5 && news2Score >= 5) isTrauma = true;

      console.log('최종 점수 : ' + totalScore);

      let lastLevel = 3;
      if(totalScore >= 5) lastLevel = 1;
      else if(totalScore >= 3 && totalScore <= 4) lastLevel = 2;
      if(isTrauma) {
        lastLevel = 1;
        setExtra("외상센터 이송 기준");
      }

      setLevel(lastLevel + '');

      console.log('최종 등급 : ' + lastLevel);

      response.name = name;
      response.scoring = scoring;
      response.scoringScore = scoringScore;
      response.level = lastLevel;

      Storage.set({key: 'selectInfo', value: JSON.stringify(response)});
    });

    setShowModal(true);
  }

  return (
    <IonPage id={ 'page_' + name } >

      {/* Header { */}
      <Header hdType="sub" title="중증도 분류" />
      {/* } Header */}


      <IonContent fullscreen >

        {/* 컨텐츠 타이틀 { */}
        <div className="ContTit">
          <h2 className="txt font_size_28 font_weight_m color_main">현장응급처치 표준지침 Scoring</h2>
          <div className="blank_box_20"></div>
          <div className="line hori short sky"></div>
        </div>
        {/* } 컨텐츠 타이틀 끝 */}


        <form action="" name="" id="">
        {scoreList.map((item, index) => 

        <div className="btnGroupsWrap ckboxStyle">
          <div className="txt_frame groupTit">
            <p className="txt font_size_20 font_weight_m">{item.nm}
              <span className="inline blank_box_5"></span>
              <span className="txt font_size_16 font_weight_l color_77">복수 선택 가능</span>
            </p>
          </div>

          <ul className="flex flex_direc_row flew_wrap lay_1">
            
            {item.list.map((row, idx) => 
            <li className="btn_frame lay_3_1">
            <label className="ck_box">
              <input type="checkbox" name="" id={index + "_" + idx} checked={row.isChecked} className="inputhide" value={row.nm} onChange={(e) => checkChecked(e, row, index, idx)}/>
              <div className="txt_frame">
                <p className="txt font_size_20 font_weight_r color_82 align_c">{row.nm}</p>
              </div>
            </label>
            </li>
            )}
          </ul>
        </div>

        )}



        {/* 환자 성명 { */}
        <div className="btnGroupsWrap">
          <div className="txt_frame groupTit">
            <p className="txt font_size_20 font_weight_m">환자성명</p>
          </div>

          <ul className="inputGroups flex flex_direc_row flew_wrap lay_1">
            
            <li className="input_box lay_1">
              <div className="input_frame">
                <IonInput value={name} onKeyUp={(e) => updateName(e)} name="" id="" className="basic txt font_size_20 font_weight_r" placeholder="환자의 성명을 입력해주세요."></IonInput>
              </div>
            </li>

          </ul>
        </div>
        {/* } 환자 성명 끝 */}


        {/* 버튼 Submit { */}
        <div className="btnGroupsWrap submitStyle">
          <div className="btn_frame">
          {/* type="submit" , routerLink 삭제하기 */}
          {/* routerLink="/hospitalinfo" */}
            {/* <IonButton type="button" onClick={() => setShowModal(true)} className="btn rounded_box_2 btn_submit font_size_18 font_weight_m">선택 완료</IonButton> */}
            <IonButton type="button" onClick={() => save()} className="btn rounded_box_2 btn_submit font_size_18 font_weight_m">선택 완료</IonButton>
          </div>
        </div>
        {/* } 버튼 Submit 끝 */}
        </form>

      </IonContent>


      {/* 레이어팝업: 환자레벨확인 { */}
      <IonModal id="LayerpopLevel" mode="ios" backdropDismiss={false} isOpen={showModal} > 
        <LayerpopLevel closeAction={closeModal} text={name} level={level} extra={extra} /> 
      </IonModal>
      {/* } 레이어팝업: 환자레벨확인  끝 */}

      {/* 레이어팝업: 경고창 { */}
      <IonModal id="LayerpopAlert" mode="ios" backdropDismiss={false} isOpen={showAlert} > 
        <LayerpopAlert closeAction={closeAlert} text={alertMessage} /> 
      </IonModal>
      {/* } 레이어팝업: 환자레벨확인  끝 */}


    </IonPage>
  );
};

export default Scoring;
