import React from 'react';
import {IonHeader, IonContent, IonButton} from '@ionic/react';
import {useParams} from 'react-router';
import values from '../assets/json/parameter.json';

type modalProps = {
  closeAction : Function;
  text: string;
  extra: string;
}


class LayerpopParameterS extends React.Component<modalProps> {

  render() {

    const paramList = [...values];
    const idx = this.props.text ? parseInt(this.props.text) : 0
    const param = paramList[idx];

    return <>
      <IonHeader className="">
        <div className="inner">
          <div className="txt_frame">
            <p className="txt font_size_24 font_weight_m">{param.nm}</p>
            <p className="txt font_size_16 color_77">{param.subNm}</p>
          </div>
        </div>
      </IonHeader>
      <IonContent className="">

        <form action="">
        <div className="btnGroupsWrap ckboxStyle allCk">
          <div className="flex flex_direc_row flew_wrap lay_1">

            {/* <IonButton className="btn_frame ht_auto lay_2_1" routerLink={"/scoring/" + item.score + "/" + this.props.extra} onClick={() => this.props.closeAction()} color="white"> */}
            {param.list.length > 0 ? param.list.map((item, index) =>   
            <IonButton className="btn_frame ht_auto lay_2_1" onClick={() => this.props.closeAction(this.props.text, item)} color="white">
              <label className="ck_box">
                <input type="radio" name="" id="" className="inputhide" value="" />
                <div className="txt_frame">
                  <p className="txt font_size_20 font_weight_r color_main align_c">{item.nm}</p>
                </div>
              </label>
            </IonButton>
            ) : ''}

          </div>
        </div>
        </form>
        
      </IonContent>
    </>
  };

}

// export default LayerpopStartClassifi;

export default ({closeAction, text, extra}: { closeAction: Function, text: string, extra: string }) => (
  <LayerpopParameterS closeAction={closeAction} text={text} extra={extra}>
  </LayerpopParameterS>
)