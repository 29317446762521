import { IonContent, IonButton, IonPage, IonToggle, useIonViewWillEnter, IonInput, IonTextarea } from '@ionic/react';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import Header from '../components/Header';
import axios from 'axios';
import global from '../assets/json/global.json';
import { Storage } from '@capacitor/storage';

import './Page.css';
import { Toast } from '@capacitor/toast';

const HospitalManage: React.FC = () => {

  const { name } = useParams<{ name: string; }>();
  const [hospitalList, setHospitalList] = useState<any[]>([]);

  async function getList() {

    const { value } = await Storage.get({key: 'loginInfo'});
    if(value != null) {
      let loginInfo = JSON.parse(value);

      axios.post<Api>(global.baseUrl + '/getList', {mb_id: loginInfo.mb_id}).then(response => {

        if(response.data.status == '00') {
          let list = JSON.parse(response.data.data);
          console.log(list);
          setHospitalList(list);
          console.log(hospitalList);
        } else {
          alert('데이터를 가져오는데 실패했습니다.');
          return;
        }

      }, 
      error => {
        console.log(error);
      }); 

    }     
  }

  function updateRow(type: string, e: any, item: any, index: number) {
      console.log(e);
      console.log(item);
      console.log(index);

      if(type == "tel") {
          item.TEL = e.target.value;
      } else if(type == "memo") {
          item.MEMO = e.target.value;
      }

      setHospitalList([...hospitalList.slice(0, index), item, ...hospitalList.slice(index+1)]);
  }

  function save() {
    axios.post<Api>(global.baseUrl + '/saveHospitalInfo', hospitalList).then(response => {

        console.log(response);
  
        if(response.data.status == '00') {
            showToast();
        } else {
        //   alert('데이터를 가져오는데 실패했습니다.');
          return;
        }
  
      }, 
      error => {
        console.log(error);
      });      
  }

  const showToast = async () => {
    await Toast.show({
      text: '병원정보 저장이 완료되었습니다.', 
      position: 'bottom'
    });
  };

  useIonViewWillEnter(() => {
      getList();
  })

  return (
    <IonPage id={ 'page_' + name } >

      {/* Header { */}
      <Header hdType="sub" title="병원정보관리" />
      {/* } Header */}


      <IonContent fullscreen >

        {/* 컨텐츠 타이틀 { */}
        <div className="ContTit">
          <h2 className="txt font_size_28 font_weight_m color_main">병원정보관리 <span className="inline blank_box_10"></span><span className="txt font_size_18 font_weight_l color_77">상황실에서 전하는 소식을 확인하세요.</span></h2>
          <div className="blank_box_20"></div>
          <div className="line hori short sky"></div>
        </div>
        {/* } 컨텐츠 타이틀 끝 */}

        <div className="ContTit">
            <table className="hospitalManageList">
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>병원명</th>
                        <th>긴급연락처</th>
                        <th>알림사항</th>
                    </tr>
                </thead>
                <tbody>
                {hospitalList.map((item: any, index) => {
                    return <tr>
                        <td className="align_c">{index + 1}</td>
                        <td>{item.NAME}</td>
                        <td><IonInput value={item.TEL} onKeyUp={(e) => updateRow('tel', e, item, index)} className="hmlInput" placeholder="긴급연락처"></IonInput></td>
                        <td><IonTextarea className="hmlTextarea" value={item.MEMO} onKeyUp={(e) => updateRow('memo', e, item, index)}></IonTextarea></td>
                    </tr>
                })}
                </tbody>
            </table>

            <IonButton type="button" onClick={() => save()} className="btn rounded_box_2 btn_submit font_size_18 font_weight_m">저장</IonButton>
        </div>

      </IonContent>

    </IonPage>
  );
};

export default HospitalManage;
