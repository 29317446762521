import {
  IonContent,
  // IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  useIonViewWillEnter,
} from '@ionic/react';
import React, { useState } from 'react';
import { render } from '@testing-library/react';
import { Storage } from '@capacitor/storage';

import { useLocation } from 'react-router-dom';
// import { archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
// import './Menu.css';

interface AppPage {
  url: string;
  // iosIcon: string;
  // mdIcon: string;
  title: string;
  cate: string;
}

let appPages: AppPage[] = [
  
  {
    title: '환자이송 시작',
    url: '/trauma',
    cate: '1'
  },
  // {
  //   title: '병원 정보',
  //   url: '/hospitalinfo',
  //   cate: '1'
  // },
  // {
  //   title: '중증도 입력 내역',
  //   url: '/',
  //   cate: '2'
  // },
  // {
  //   title: '사용자 관리',
  //   url: '/',
  //   cate: '2'
  // },
  {
    title: '공지사항',
    url: '/notice',
    cate: '2'
  },
  // {
  //   title: '설정',
  //   url: '/',
  //   cate: '3'
  // }
];

// let myInfo = '대전둔산소방서';

const Menu: React.FC = () => {

  const [myInfo, setMyInfo] = useState("");

  const checkLogged = async () => {
    const { value } = await Storage.get({key: 'loginInfo'});
    if(value != null) {
      let loginInfo = JSON.parse(value);
      console.log(loginInfo);
      setMyInfo(loginInfo.name);

      if(loginInfo.mb_type == 'ADMIN') {
        // setAppPages([...appPages, {title: '병원별공지관리', url: '/', cate: '3'}]);
        if(appPages.length == 2) {
          appPages.push({title: '병원 정보', url: '/hospitalinfo', cate: '1'});
          appPages.push({title: '병원정보관리', url: '/hospitalManage', cate: '2'});
          appPages.push({title: '공지사항등록', url: '/noticeWrite', cate: '2'});
          appPages.push({title: '엑셀다운로드', url: 'https://api.weurgent.kr/api/getExcel', cate: '3'});
        }
      } else if(loginInfo.mb_type == 'HOSPITAL') {
        if(appPages.length == 2) {
          appPages.push({title: '병원정보관리', url: '/hospitalManage', cate: '2'});
        }        
      }

    }
  };

  function openWindow(url: string) {
    window.open(url);
  }

  checkLogged();

  const logout = async () => {
    await Storage.remove({key: 'loginInfo'});
    window.location.href = '/login';
  };

  let selectInfo = {
    name: "", 
    trauma: -1, 
    traumaStr: "", 
    scoring: "",
    scoringScore: 0, 
    news2: "", 
    news2Total: 0, 
    news2Score: 0, 
    level: 0, 
    isBurn: false
  };
  function resetVars() {
    Storage.set({key: 'selectInfo', value: JSON.stringify(selectInfo)});
  }
  

  const location = useLocation();

  return (
    <IonMenu contentId="main" side="end" >
      <IonContent id="gnb">
        <IonList id="inbox-list">

          <IonListHeader className="bg_c_main">
            <div className="inner width_100 flex flex_direc_col flex_align_start">
              <div className="lay_1 flex flex_direc_row flex_space_between">
                <div className="left flex flex_direc_row flex_align_center">

                  {/* 버튼: 홈 */}
                  <IonMenuToggle>
                    <IonItem routerLink={'/home'}>
                      <div className="flex flex_direc_row flex_align_center">
                        <i className="ico ico_btn_home"></i>
                        <p className="txt font_size_16 font_color_white font_weight_r">홈</p>
                      </div>
                    </IonItem>
                  </IonMenuToggle>

                  {/* 버튼: 로그아웃 */}
                  <IonMenuToggle>
                    {/* <IonItem routerLink={'/login'}> */}
                    <IonItem onClick={() => logout()}>
                      <div className="flex flex_direc_row flex_align_center">
                        <i className="ico ico_btn_logout"></i>
                        <p className="txt font_size_16 font_color_white font_weight_r">로그아웃</p>
                      </div>
                    </IonItem>
                  </IonMenuToggle>
                </div>
                <div className="right">
                  <div className="btn_frame">
                    {/* 버튼: 메뉴 닫기 */}
                    <IonMenuToggle>
                      <div className="ico_frame">
                        <i className="ico ico_btn_close"></i>
                      </div>
                    </IonMenuToggle>
                  </div>
                </div>
              </div>

              <div className="blank_box_40"></div>

              <div className="txt_frame">
                <p className="txt font_size_22 font_weight_m font_color_white">{myInfo}</p>
              </div>

              <div className="blank_box_20"></div>

              {/* 버튼: 마이페이지 */}
              {/*
              <IonMenuToggle>
                <IonItem routerLink="/home">
                  <div className="txt_frame">
                    <p className="txt font_size_16 font_weight_r font_color_white flex flex_direc_row flex_align_center">마이페이지 <span className="inline blank_box_5"></span><i className="ico ico_btn_arr_my"></i></p>
                  </div>
                </IonItem>
              </IonMenuToggle>
              */}

              <div className="blank_box_40"></div>
            </div>
          </IonListHeader>

          
          <div className="listGroup">
            <ul>
              {appPages.map((appPage, index) => {
                if(appPage.cate == '1') {
                  return (
                    <li>
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false} onClick={() => resetVars()}>
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    </li>
                  );
                }
              })}
            </ul>
            {/* <div className="line_frame"><div className="line hori"></div></div> */}
            <ul>
              {appPages.map((appPage, index) => {
                if(appPage.cate == '2') {
                  return (
                    <li>
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false} onClick={() => resetVars()}>
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    </li>
                  );
                }                
              })}
            </ul>
            <div className="line_frame"><div className="line hori"></div></div>
            <ul>
              {appPages.map((appPage, index) => {
                if(appPage.cate == '3') {
                  
                  return (
                    <li className="">
                    <IonMenuToggle key={index} autoHide={false}>
                      <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerDirection="none" lines="none" detail={false} onClick={() => {openWindow(appPage.url);resetVars()}}>
                        <IonLabel className="">
                          {appPage.title} 
                        
                          {/* 공지사항 뱃지 */}
                          {/* <span className="inline yellowLabel ml_5">2</span> */}
                          
                        </IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                    </li>
                  );
                }                
              })}
            </ul>
          </div>

        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
