import { IonContent, IonButton, IonPage, useIonViewWillEnter } from '@ionic/react';
import { RouteComponentProps, useParams } from 'react-router';
import { useState } from 'react';
import Header from '../components/Header';
import { Storage } from '@capacitor/storage';

import './Page.css';
import { attachProps } from '@ionic/react/dist/types/components/utils';

const Trauma: React.FC<RouteComponentProps> = (props) => {

  const traumaList = [
    '관통 또는 자상(머리, 목, 가슴, 배, 상완, 대퇴부)', 
    '흉곽의 불안정 또는 변형(동요가슴)', 
    '압궤, 벗겨진, 썰린, 맥박이 소실된 사지, 손목 또는 발목 상부의 절단', 
    '열린 또는 함몰 두개골 골절, 외상성 마비', 
    '그 외 119 구급대원 판단', 
    '해당 없음'
  ];

  const { name } = useParams<{ name: string; }>();
  const [ trauma, setTrauma ] = useState(-1);

  useIonViewWillEnter(() => {
    getSelectInfo().then(response => {
      console.log('::::: Trauma > useIonViewWillEnter > getSelectInfo :::::');
      console.log(response);
      console.log('response trauma : ' + response.trauma + ' / trauma : ' + trauma);
      if(response.trauma != trauma) {
        setTrauma(response.trauma);
      } else {
        setTrauma(-1);
      }
    });
  });

  const getSelectInfo = async () => {
    const { value } = await Storage.get({key: 'selectInfo'});
    return JSON.parse(value ? value : "");
  };

  function save(idx: number) {

    console.log('::::: Trauma > save :::::');
    console.log('::::: Trauma > save > setTrauma('+idx+') :::::');

    getSelectInfo().then(response => {
      response.trauma = idx;
      response.traumaStr = traumaList[idx];
      Storage.set({key: 'selectInfo', value: JSON.stringify(response)});

      if(idx < 5) props.history.push('/scoring');
      else if(idx == 5) props.history.push('/traumanon');
    });

  };


  return (
    <IonPage id={ 'page_' + name } >

      {/* Header { */}
      <Header hdType="sub" title="중증도 분류" />
      {/* } Header */}


      <IonContent fullscreen >

        {/* 컨텐츠 타이틀 { */}
        <div className="ContTit">
          <h2 className="txt font_size_28 font_weight_m color_main">외상 환자</h2>
          <div className="blank_box_20"></div>
          <div className="line hori short sky"></div>
        </div>
        {/* } 컨텐츠 타이틀 끝 */}


        {/* 중증도분류-외상환자 1 { */}
        <div className="btnGroupsWrap listStyle">
          <div className="txt_frame groupTit">
            <p className="txt font_size_20 font_weight_m">중증 외상 센터 이송 기준</p>
          </div>

          <ul className="flex flex_direc_row flew_wrap lay_1">
            
            <li className="btn_frame lay_1">
              <IonButton className={"btn rounded_box boxshadow " + (trauma == 0 ? "selected" : "white")}  color="white" onClick={() => save(0)}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{traumaList[0]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className="btn_frame lay_1">
              <IonButton className={"btn rounded_box boxshadow " + (trauma == 1 ? "selected" : "white")}  color="white" onClick={() => save(1)}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{traumaList[1]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className="btn_frame lay_1">
              <IonButton className={"btn rounded_box boxshadow " + (trauma == 2 ? "selected" : "white")}  color="white" onClick={() => save(2)}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{traumaList[2]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className="btn_frame lay_1">
              <IonButton className={"btn rounded_box boxshadow " + (trauma == 3 ? "selected" : "white")}  color="white" onClick={() => save(3)}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{traumaList[3]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className="btn_frame lay_1">
              {/* <IonButton className="btn rounded_box white boxshadow"  color="white" routerLink="/scoring/5/trauma"> */}
              <IonButton className={"btn rounded_box boxshadow " + (trauma == 4 ? "selected" : "white")}  color="white" onClick={() => save(4)}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{traumaList[4]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

            <li className="btn_frame lay_1">
              {/* <IonButton className="btn rounded_box white boxshadow"  color="white" routerLink="/traumanon/trauma"> */}
              <IonButton className={"btn rounded_box boxshadow " + (trauma == 5 ? "selected" : "white")}  color="white" onClick={() => save(5)}>
                <p className="txt font_size_22 font_weight_r flex flex_glow_1">{traumaList[5]}</p>
                <i className="ico ico_btn_arr"></i>
              </IonButton>
            </li>

          </ul>
        </div>
        {/* } 중증도분류-외상환자 1 */}

      </IonContent>


    </IonPage>
  );
};

export default Trauma;
