import { IonContent, IonButton, IonPage, IonToggle, useIonViewWillEnter } from '@ionic/react';
import React from 'react';
import { RouteComponentProps, useParams } from 'react-router';
import Header from '../components/Header';

import axios from 'axios';
import { useState } from 'react';
import global from '../assets/json/global.json';
import { Storage } from '@capacitor/storage';

import './Page.css';

const Notice: React.FC<RouteComponentProps> = (props) => {


  const { name } = useParams<{ name: string; }>();
  const [ noticeList, setNoticeList ] = useState<any[]>([]);
  const [ isAdmin, setIsAdmin ] = useState(0);

  function getNoticeList() {

    axios.post<Api>(global.baseUrl + '/getNoticeList').then(response => {

      console.log(response);

      if(response.data.status == '00') {
        let data: any = response.data.data;
        setNoticeList(data);
        // alert(data.cnt);
      } else {
        // alert('데이터를 가져오는데 실패했습니다.');
        return;
      }
    }, 
    error => {
      console.log(error);
    });

  }

  function openUpdate(uid: string) {
    props.history.push('/noticeWrite/' + uid);
  }

  function delNotice(uid: string) {

    if(window.confirm('삭제후에는 복구할 수 없습니다. 삭제하시겠습니까?')) {
      let params = {uid: uid};
      let strParams = JSON.stringify(params);
      axios.post<Api>(global.baseUrl + '/delNotice', strParams).then(response => {
        getNoticeList();
      }, 
      error => {
        console.log(error);
      });        
    }

  }

  const toggleClass = (idx: number) => {

    let item = noticeList[idx];
    item.IS_OPEN = !item.IS_OPEN;

    setNoticeList([...noticeList.slice(0, idx), item, ...noticeList.slice(idx+1)])

  }  

  useIonViewWillEnter(() => {
    getNoticeList();
    adminCheck();    
  })

  const adminCheck = async () => { //수정버튼 출력을 위한 관리자 권한 체크
    const { value } = await Storage.get({key: 'loginInfo'});
    if(value != null) {
      let loginInfo = JSON.parse(value);
      if(loginInfo.mb_type == 'ADMIN') {
        console.log('1111');
        setIsAdmin(1);
      }
    }
  };  

  return (
    <IonPage id={ 'page_' + name } >

      {/* Header { */}
      <Header hdType="sub" title="공지사항" />
      {/* } Header */}


      <IonContent fullscreen >

        {/* 컨텐츠 타이틀 { */}
        <div className="ContTit">
          <h2 className="txt font_size_28 font_weight_m color_main">공지사항 <span className="inline blank_box_10"></span><span className="txt font_size_18 font_weight_l color_77">상황실에서 전하는 소식을 확인하세요.</span></h2>
          <div className="blank_box_20"></div>
          <div className="line hori short sky"></div>
        </div>
        {/* } 컨텐츠 타이틀 끝 */}


        {/* 목록 { */}
        <div className="boardListWrap">
          
          {/* 리스트- 공지 { */}
          <ul className="listGroup noti width_100">
            
          </ul>
          {/* } 리스트- 공지 */}


          {/* 리스트- 일반 { */}
          <ul className="listGroup normal width_100">
          {noticeList.map((item: any, index) => {
                    return <li className={`list_item ${item.IS_OPEN ? "act" : null}`} onClick={() => toggleClass(index)}>
              <div className="lstCon tit">
                <div className="left flex flex_glow_1">
                  <div className="txt_frame">
                    <p className="txt lname font_size_24 font_weight_m">
                      {/* 공지표시 */} 
                      {/* <span className="notiLabel inline font_size_16 font_weight_m">공지</span>  */}
                      {/* 제목 */} {item.SUBJECT}
                      <button className={`small_btn ${isAdmin == 0 ? "displaynone" : ""}`} onClick={() => openUpdate(item.TB_NOTICE_UID)}>수정</button>
                      <button className={`small_btn ${isAdmin == 0 ? "displaynone" : ""}`} onClick={() => delNotice(item.TB_NOTICE_UID)}>삭제</button>
                    </p>
                    <p className="txt ldate font_size_18 color_99">
                      {/* 날짜 */} {item.REG_DT}
                    </p>
                  </div>
                </div>
                <div className="right">
                  <div className="ico_frame"><i className="ico ico_btn_more"></i></div>
                </div>
              </div>

              <div className="lstCon con">
                <div className="inner">
                  {/* 이미지 영역 (이미지가 있을 경우) { */}
                  {/*
                  <div className="img_frame">
                    <img src="assets/images/sample/sample-img.png" alt="" />
                  </div>
                  */}
                  {/* } 이미지 영역 */}

                  {/* 텍스트 영역 { */}
                  <div className="txt_frame pre font_size_22 font_weight_r color_66 lineheight_15">
                  {item.CONTENTS}
                  </div>
                  {/* } 텍스트 영역 */}
                </div>
                <div className="blank_box_20"></div>
              </div>
            </li>
            })}
          </ul>
          {/* } 리스트- 일반 */}

        </div>
        {/* } 목록 */}

      </IonContent>

    </IonPage>
  );
};

export default Notice;
