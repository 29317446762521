import React from 'react';
import {IonHeader, IonContent, IonButton, IonFooter} from '@ionic/react';


type modalProps = {
  closeAction : Function;
  text: string;
  level: string;
  extra: string;
}


class LayerpopLevel extends React.Component<modalProps> {

  render() {
    return <>
      {/* <div className="backDropBg"></div> */}
      <IonHeader className="">
        <div className="blank_box_20"></div>
        <div className="inner pdbottom_0">
          <div className="txt_frame">
            <p className="txt font_size_24 font_weight_m align_c lineheight_16"> 
              {this.props.text} 님의 레벨은 
              <br /> 
              <b className={"font_weight_b stat_level_" + this.props.level}>{this.props.extra != "" ? this.props.extra : "Level " + this.props.level}</b> 에 해당됩니다. 
            </p>
          </div>
        </div>
      </IonHeader>

      <IonContent>
      </IonContent>

      <IonFooter>
        <div className="inner">
          <div className="btnGroupsWrap submitStyle flex flex_direc_row flex_align_center flex_space_between">

            <div className="btn_frame flex flex_glow_1" onClick={() => this.props.closeAction()}>
              {/* <IonButton type="button" routerLink={"/hospitalinfo/" + this.props.level +"/" + this.props.extra} className="btn rounded_box_2 btn_submit font_size_18 font_weight_m">병원 정보 목록 보기</IonButton> */}
              <IonButton type="button" className="btn rounded_box_2 btn_submit font_size_18 font_weight_m">병원 정보 목록 보기</IonButton>
            </div>

          </div>
        </div>
      </IonFooter>
    </>
  };

}

// export default LayerpopStartClassifi;

export default ({closeAction, text, level, extra}: { closeAction: Function, text:string, level:string, extra: string}) => (
  <LayerpopLevel closeAction={closeAction} text={text} level={level} extra={extra}>
  </LayerpopLevel>
)