import { IonContent, IonButton, IonPage, IonToggle, useIonViewWillEnter, IonInput, IonTextarea } from '@ionic/react';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import Header from '../components/Header';
import axios from 'axios';
import global from '../assets/json/global.json';
import { Storage } from '@capacitor/storage';

import './Page.css';
import { Toast } from '@capacitor/toast';

const NoticeWrite: React.FC = () => {

  const { name, uid } = useParams<{ name: string, uid: string }>();
  const [subject, setSubject] = useState("");
  const [contents, setContents] = useState("");

  async function save() {

    const { value } = await Storage.get({key: 'loginInfo'});
    if(value != null) {
      let loginInfo = JSON.parse(value);

      axios.post<Api>(global.baseUrl + '/saveNotice', {uid: uid, mb_id: loginInfo.mb_id, subject: subject, contents: contents}).then(response => {

        console.log(response);
  
        if(response.data.status == '00') {
          alert("저장이 완료되었습니다.");
          if(uid != "") {

          } else {
            setSubject("");
            setContents("");            
          }
        } else {
        //   alert('데이터를 가져오는데 실패했습니다.');
          return;
        }
  
      }, 
      error => {
        console.log(error);
      });        

    }    
  }

  const showToast = async () => {
    await Toast.show({
      text: '공지사항 저장이 완료되었습니다.', 
      position: 'bottom'
    });
  };

  const updateSubject = (event: any) => {
    setSubject(event.target.value)
  }
  const updateContents = (event: any) => {
    setContents(event.target.value)
  }

  useIonViewWillEnter(() => {

    console.log(uid);

    if(uid != "") {

      let params = {uid: uid};
      let strParams = JSON.stringify(params);
      axios.post<Api>(global.baseUrl + '/getNotice', strParams).then(response => {

        console.log(response.data.data);
        const data: any = response.data.data;
        
        setSubject(data.SUBJECT);
        setContents(data.CONTENTS);
  
      }, 
      error => {
        console.log(error);
      });
    }
      
  })

  return (
    <IonPage id={ 'page_' + name } >

      {/* Header { */}
      <Header hdType="sub" title="공지사항 등록" />
      {/* } Header */}


      <IonContent fullscreen >

        {/* 컨텐츠 타이틀 { */}
        <div className="ContTit">
          <h2 className="txt font_size_28 font_weight_m color_main">공지사항 등록 <span className="inline blank_box_10"></span><span className="txt font_size_18 font_weight_l color_77">상황실에서 전하는 소식을 확인하세요.</span></h2>
          <div className="blank_box_20"></div>
          <div className="line hori short sky"></div>
        </div>
        {/* } 컨텐츠 타이틀 끝 */}

        <div className="ContTit">
            <table className="noticeWrite">
                <tbody>
                  <tr>
                    <th>제목</th>
                    <td><IonInput value={subject} onKeyUp={(e) => updateSubject(e)} className="hmlInput" placeholder="공지사항 제목"></IonInput></td>
                  </tr>
                  <tr>
                    <th>내용</th>
                    <td><IonTextarea rows={10} className="hmlTextarea height_300" value={contents} onKeyUp={(e) => updateContents(e)}></IonTextarea></td>
                  </tr>
                </tbody>
            </table>

            <div className="blank_box_30"></div>

            <IonButton type="button" onClick={() => save()} className="btn rounded_box_2 btn_submit font_size_18 font_weight_m">저장</IonButton>
        </div>

      </IonContent>

    </IonPage>
  );
};

export default NoticeWrite;
