import { IonContent, IonButton, IonPage, IonLabel, IonModal, useIonViewWillEnter, IonFooter } from '@ionic/react';
import { RouteComponentProps, useParams } from 'react-router';
import { useState } from 'react';
import { Storage } from '@capacitor/storage';
import { Toast } from '@capacitor/toast';
import { Geolocation } from '@capacitor/geolocation';
import Header from '../components/Header';
import Footer from '../components/Footer';
import values from '../assets/json/hospital_base.json';
import axios from 'axios';
import global from '../assets/json/global.json';

import './Page.css';
import { saveConfig } from '@ionic/core';


const HospitalInfo: React.FC<RouteComponentProps> = (props) => {

  const { name, subject, extra } = useParams<{ name: string, subject: string, extra: string }>();

  /*
  let list = [];
  if(subject == "화상") {
    list = [...valuesw];
  } else if(extra == "trauma") {
    list = [...valuese];
  } else {
    list = [...values];
  }
  */

  let latitude: number, longitude: number;

  const getLocation = async () => {
    const position = await Geolocation.getCurrentPosition({enableHighAccuracy: true, timeout: 1000});
    // latitude = position.coords.latitude;
    // longitude = position.coords.longitude;
    console.log('lat : ' + latitude + ' / lng : ' + longitude);
    return position;
  };

  const [hospitalList, setHospitalList] = useState([...values]);

  useIonViewWillEnter(() => {
    getLocation().then(response => {
      console.log('::::: HospitalInfo > useIonViewWillEnter > getLocation');
      latitude = response.coords.latitude;
      longitude = response.coords.longitude;
      getSelectInfo().then(response => {
        console.log('::::: HospitalInfo > useIonViewWillEnter > getSelectInfo');
        console.log(response);
        getList(response);
        setInterval(() => getList(response), 300000);      
      });
    }, error => {
      console.log('gps error');
      console.log(error);
      getSelectInfo().then(response => {
        console.log('::::: HospitalInfo > useIonViewWillEnter > getSelectInfo');
        console.log(response);
        getList(response);
        setInterval(() => getList(response), 300000);      
      });      
    });

    save();
  });

  function initList() {
    // console.log('::::: HospitalInfo > useIonViewWillEnter > initList');
    // getLocation().then(response => {
      // console.log('::::: HospitalInfo > useIonViewWillEnter > getLocation');
      getSelectInfo().then(response => {
        console.log('::::: HospitalInfo > useIonViewWillEnter > getSelectInfo');
        console.log(response);
        getList(response);
      });
    // });   
  }

  function openLink(url: any) {
    window.open(url);
  }

  function getList(selectInfo: any) {

    let news2Score = selectInfo.news2Score;
    let isTrauma = selectInfo.trauma > -1 && selectInfo.trauma < 5 ? true : false;
    if(selectInfo.trauma == 5 && news2Score >= 5) isTrauma = true;

    let params = {
      level: selectInfo.level, 
      isBurn: selectInfo.isBurn ? "true" : "false", 
      isTrauma: isTrauma ? "true" : "false", 
      latitude: latitude ? latitude : 0, 
      longitude: longitude ? longitude : 0
    };
    let strParams = JSON.stringify(params);

    axios.post<Api>(global.baseUrl + '/getList', strParams).then(response => {

      console.log(response);

      if(response.data.status == '00') {
        setHospitalList(JSON.parse(response.data.data));
      } else {
        alert('데이터를 가져오는데 실패했습니다.');
        return;
      }


    }, 
    error => {
      console.log(error);
    });

  }

  const getSelectInfo = async () => {
    const { value } = await Storage.get({key: 'selectInfo'});
    return JSON.parse(value ? value : "");
  };

  const toggleClass = (idx: number) => {

    let item = hospitalList[idx];
    item.IS_OPEN = !item.IS_OPEN;

    setHospitalList([...hospitalList.slice(0, idx), item, ...hospitalList.slice(idx+1)])

  }

  async function save() {

    let loginInfo: any;
    const ER_CODE = "";

    const { value } = await Storage.get({key: 'loginInfo'});
    if(value != null) {
      let loginInfo = JSON.parse(value);
      

      getSelectInfo().then(response => {
        console.log('::::: HospitalInfo > useIonViewWillEnter > getSelectInfo');
        console.log(response.item);
        
        let params = {
          MB_ID: loginInfo.mb_id, 
          CODE: ER_CODE, 
          NAME: response.name, 
          EXTRA: response.extra, 
          LOG: response
        };
        let strParams = JSON.stringify(params);

        axios.post<Api>(global.baseUrl + '/save', strParams).then(response => {
    
          if(response.data.status == '00') {
            showToast();          
            // props.history.push('/home', null);
          } else {
            alert('서버와의 통신에 실패했습니다.');
            return;
          }
    
    
        }, 
        error => {
          console.log(error);
        });      

      });
    } else {
      alert("로그인 정보가 없습니다.");
      return;
    }

  }

  const showToast = async () => {
    await Toast.show({
      text: '이송정보 저장이 완료되었습니다.', 
      position: 'bottom'
    });
  };

  return (

    <IonPage id={ 'page_' + name } >

      {/* Header { */}
      <Header hdType="sub" title="병원 정보" />
      {/* } Header */}


      <IonContent fullscreen >
       
        <div className="itemGroupsWrap">
          <ul className="flex flex_direc_row flew_wrap lay_1">
            
            {/* 반복 { */}
            {hospitalList.map((item, index) => {
              return <li className="box_frame lay_1">
              {/* 목록 클릭시 상세 정보 펼침
                  - white_box 태그에 open 클래스 추가
                  - (예) .white_box.boxshadow.open
               */}
              <div className={`white_box boxshadow ${item.IS_OPEN ? "open" : null}`}>
                <div className="boxtit width_100 flex flex_direc_row flex_align_center flex_space_between">
                  <div className="flex flex_glow_1">
                    {/* 레벨
                      [ 상태별 클래스 ]
                      - Level 1 : .level_1
                      - Level 2 : .level_2
                      - Level 3 : .level_3
                    */}
                    <span className={`inline label_level font_size_16 font_weight_m color_white level_${item.LEVEL}`}>Level {item.LEVEL}</span>
                    <div className="inline blank_box_15"></div>
                    <p className="txt font_size_24 font_weight_m">
                      {/* 병원 이름 */}
                      {item.NAME}
                      <span className="inline blank_box_10"></span>
                      <span className="font_size_16 font_weight_r color_77">
                        {/* 병원종류 */}
                        {/* {item.INFO} */}
                      </span>
                    </p>
                  </div>
                  <div className="flex flex_direc_row flex_align_center">
                    <div className="btn_frame">
                      {/* 전화걸기 버튼 */}
                      <a href={"tel:" + item.TEL} className="btn inline btn_call">
                        <p className="txt font_size_16 font_weight_m color_66 flex flex_direc_row flex_allign_center"><i className="ico ico_btn_phone"></i> <span className="xs_hide">응급의료센터</span></p>
                      </a>
                    </div>

                    <div className="blank_box_15"></div>

                    <div className="btn_frame">
                      {/* 이송 버튼 */}
                      {/* <IonButton type="button" onClick={() => save(item.CODE)} className="btn rounded_box_3 nowidth noheight btn_submit font_size_16 font_weight_m">이송</IonButton> */}
                    </div>
                  </div>
                </div>


                {/* 간략정보 { */}
                <div className="boxtit simple_info" onClick={() => toggleClass(index)}>
                  <div className="inner wdith_100 flex flex_direc_row flew_wrap">
                    {/* 병상수 정보 { */}
                    <div className="flex flex_glow_2 flex_direc_row flew_wrap flex_align_center">
                      {/* 
                        [ .infolabel 클래스 ]
                        - 전체병상 : (기본)
                        - 재실 : .red
                        - 소독중 : .yellow
                        - 여유병상 : .green
                        - 접수대기 : .navy
                        - 일반격리, 음압격리 : .grey
                      */}
                      <div className="infolabel">
                        <span className="txt font_size_16 font_weight_r">총 병상수</span>
                        <span className="txt font_size_16 font_weight_m">{item.TOTAL_CNT}</span>
                      </div>

                      <div className="infolabel red">
                        <span className="txt font_size_16 font_weight_r">재실환자</span>
                        <span className="txt font_size_16 font_weight_m">{item.USE_CNT}</span>
                      </div>

                      <div className="infolabel green">
                        <span className="txt font_size_16 font_weight_r">여유병상</span>
                        <span className="txt font_size_16 font_weight_m">{item.REMAIN_CNT}</span>
                      </div>

                      <div className="infolabel navy">
                        <span className="txt font_size_16 font_weight_r">접수대기</span>
                        <span className="txt font_size_16 font_weight_m">{item.WAIT_CNT}</span>
                      </div>
                    </div>
                    {/* } 병상수 정보 */}

                  </div>

                  {/* 우측 버튼 { */}
                  <div>
                    <IonButton type="button" className="btn btn_ico" color="white"><i className="ico ico_btn_more"></i></IonButton>
                  </div>
                  {/* } 우측 버튼 */}

                </div>
                {/* } 간략정보 끝 */}



                <div className="boxcon">

                  {/* 상세정보 1- 일반병상 표 { */}
                  <div className="boxcon_item">
                    <div className="table_wrap">
                      <table className="basic_tbl size_normal align_c_all">
                        <thead>
                          <tr>
                            <th className="thtxt"><p className="txt">구역별</p></th>
                            <th className="thtxt"><p className="txt">구역정보</p></th>
                            <th className="thtxt"><p className="txt">구역상태</p></th>
                            <th className="thtxt"><p className="txt">잔여 병상수</p></th>
                            <th className="thtxt"><p className="txt">총 병상수</p></th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* 테이블 반복 { */}
                          {item.BASE_LIST.map((row) => 
                          <tr>
                            <td>
                              {/* 구역명 */}
                              <p className="txt">{row.NAME}</p>
                            </td>
                            <td>
                              {/* 과목명 */}
                              <p className="txt">{row.INFO}</p>
                            </td>
                            <td>
                              {/* 구역상태 
                                [ 상태별 클래스 ]
                                - 사용가능 : .color_green
                                - 불가능 : .color_red
                              */}

                              <p className={`txt ${row.STATUS == "정상" ? "color_green" : "color_red"}`}>{row.STATUS}</p>
                            </td>
                            <td>
                              {/* 잔여병상수 */}
                              <p className="txt">{row.REMAIN_CNT}</p>
                            </td>
                            <td>
                              {/* 총 병상수 */}
                              <p className="txt">{row.TOTAL_CNT}</p>
                            </td>
                          </tr>
                          )}
                          {/* } 테이블 반복 끝 */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* } 상세정보 1- 일반병상 표 끝 */}


                  {/* 상세정보 2- 격리병상 표 { */}
                  <div className="boxcon_item">
                    <div className="txt_frame itemtit">
                      <p className="txt font_size_16 font_weight_m">격리병상 정보</p>
                    </div>
                    <div className="blank_box_10"></div>
                    <div className="table_wrap">
                      <table className="basic_tbl size_normal align_c_all">
                        <thead>
                          <tr>
                            <th className="thtxt"><p className="txt">병상명</p></th>
                            <th className="thtxt"><p className="txt">구역상태</p></th>
                            <th className="thtxt"><p className="txt">잔여 병상수</p></th>
                            <th className="thtxt"><p className="txt">총 병상수</p></th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* 테이블 반복 { */}
                          {item.ISOLATE_LIST.map((row) => 
                          <tr>
                            <td>
                              {/* 병상명 */}
                              <p className="txt">{row.NAME}</p>
                            </td>
                            <td>
                              {/* 구역상태 
                                [ 상태별 클래스 ]
                                - 사용가능 : .color_green
                                - 격리중 : .color_orange
                                - 불가능 : .color_red
                              */}
                              <p className={`txt ${row.STATUS == "정상" ? "color_green" : "color_red"}`}>{row.STATUS}</p>
                            </td>
                            <td>
                              {/* 잔여병상수 */}
                              <p className="txt">{row.REMAIN_CNT}</p>
                            </td>
                            <td>
                              {/* 총 병상수 */}
                              <p className="txt">{row.TOTAL_CNT}</p>
                            </td>
                          </tr>
                          )}
                          {/* } 테이블 반복 끝 */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* } 상세정보 2- 격리병상 표 끝 */}


                  {/* 상세정보 3- 비고 { */}
                  <div className="boxcon_item">
                    <div className="txt_frame itemtit">
                      <p className="txt font_size_16 font_weight_m color_red">꼭 확인하세요</p>
                    </div>
                    <div className="blank_box_10"></div>
                    <div className="border_2px lay_1">
                      <div className="pdAll_20">
                        <div className="txt font_size_16">
                        <pre>{item.MEMO}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* } 상세정보 3- 비고 끝 */}

                </div>
              </div>
            </li>

            })}
          </ul>

          <button className="hi-extra-btn" onClick={() => openLink('https://djma.org/content/emergency')}>휴일응급수술병원 <img src="assets/images/btn/ico-open.png" alt="" /></button>
          <button className="hi-extra-btn" onClick={() => openLink('https://www.djpmhc.or.kr/sub.php?menukey=25')}>지역정신건강기관 <img src="assets/images/btn/ico-open.png" alt="" /></button>

        </div>

      </IonContent>


      {/* Footer { */}
      {/* <Footer /> */}
      <IonFooter class="footer flex_alignself_end">
        <div className="ft_inner flex flex_direc_col">

          {/* <div className="btn_frame">
            <IonButton className="btn ft_btn white" color="white"  onClick={() => setShowModal(true)}>
              <p className="txt font_size_16 font_wegiht_m color_main">필터설정</p>
              <div className="ico_frame"><i className="ico ico_btn_filter"></i></div>
            </IonButton>
          </div> */}

          <div className="btn_frame">
            <IonButton className="btn ft_btn white" color="white" onClick={() => initList()}>
              <p className="txt font_size_16 font_wegiht_m color_main">새로고침</p>
              <div className="ico_frame"><i className="ico ico_btn_ref"></i></div>
            </IonButton>
          </div>
          
        </div>
      </IonFooter>      
      {/* } Footer */}
    
    </IonPage>
  );
};

export default HospitalInfo;
